.floatBtnContent {
  text-align: center;
  z-index: 1000;
  width: calc(100% - 62px);
  height: 54px;
  bottom: 15px;
  background: var(--app-primary-color);
}

.content {
  // margin-top: 50px;
  margin: 80px 24px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1060px;
  // margin: 0 auto;
  color: var(--app-text-color);
  // margin-bottom: 184px;
  // height: 300px;
  padding: 50px 24px;
  background: var(--app-primary-light-color);
  // background-image: url('/images/planbowl/home-bottom-bg.webp');
  background-image: url('https://res-front.pumpsoul.com/pb-prod/image/home-bottom-bg.webp');

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 28px;
  margin-bottom: 240px;

  > .title {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 0;
    margin-bottom: 30px;
  }

  > .btnContainer {
    position: relative;
    display: inline-block;

    > .signUpBtn {
      // min-width: 300px;
      border: none;
      padding: 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 54px;
      border-radius: 27px;
      font-size: 16px;
      color: #ffffff;
      background: var(--app-primary-light-color);
      padding: 0 60px;
      font-weight: 700;
    }

    > .tip {
      position: absolute;
      display: inline-flex;
      transform: rotate(5deg);
      align-items: center;
      justify-content: center;
      top: -15px;
      right: -10px;
      font-size: 12px;
      height: 24px;
      border-radius: 12px;
      padding: 0 5px;
      background-color: #ff5a5a;
      color: var(--other-text-color);
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        bottom: -50%;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: #ff5a5a;
        transform: translate(-50%, -30%);
        // transform: rotate(5deg);
      }
    }
  }
}

@media screen and (min-width: 899px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: flex;
    // margin-bottom: 164px;

    > .title {
      margin-right: 80px;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    // margin-bottom: 134px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 576px) {
  .floatBtnContent {
    display: block;
  }
  .content {
    display: none;
    margin: 50px 0;
  }
}

